import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/.pnpm/next@14.0.4_@babel+core@7.23.6_react-dom@18.2.0_react@18.2.0_sass@1.69.5/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/next/node_modules/.pnpm/next@14.0.4_@babel+core@7.23.6_react-dom@18.2.0_react@18.2.0_sass@1.69.5/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/advantages/advantages.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/hero/hero.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/blog-slider/blog-slider.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/localization/localizations-copy.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/newsletter/newsletter-form.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/ui/button/button.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/ui/button-big/button-big.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/localization/localization.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/scoring/scoring.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/ui/radial-chart/radial-chart.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/newsletter/newsletter.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_homepage/prevention/prevention.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_global/cta/cta.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/_homepage/tiles/tiles.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/next/src/components/ui/tile/tile.module.scss")